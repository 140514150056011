import { ArrowUpIcon } from '@heroicons/react/solid';
import { useState, useEffect } from 'react';
import EssayCard from '../../Cards/essayCard';
import EditIcon from '../../Icons/edit';
var twitter = require('twitter-text');
import firebase from 'firebase/app';
import 'firebase/database';
import EmptyStateSection from '../../EmptyState';
import ThreadSocialBlogCard from './thread';
import EssaySocialBlogCard from './essay';
import CollectionIcon from '../../Icons/collection';
import router from 'next/router';

export default function CollectionPreview({
  collectionMeta,
  creatorMeta,
  colllectionCreatorID,
  colllectionCreatorSlug,
  collectionSlug,
  edit,
}) {
  const [collectionContent, setCollectionContent] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getContent();
  }, []);

  async function getContent() {
    const ref1 = await firebase
      .database()
      .ref('gallery')
      .child(colllectionCreatorSlug)
      .child(colllectionCreatorID)
      .child('collections')
      .child(collectionSlug)
      .child('content')
      .orderByChild('order')
      .once('value');
    const ref2 = await firebase
      .database()
      .ref('gallery')
      .child(colllectionCreatorSlug)
      .child(colllectionCreatorID)
      .child('collections')
      .child(collectionSlug)
      .child('content')
      .orderByChild('added')
      .once('value');

    var hasOrder = [];
    ref1.forEach((snapshot) => {
      if (snapshot.exists()) {
        if (snapshot.hasChild('order')) {
          //do stuff

          var hasContentID = snapshot.hasChild('content');

          hasOrder.push({
            key: snapshot.key,
            val: snapshot.val(),
            hasContentID: hasContentID,
          });
          /* tempCards.push(doc) */
        }
      }
    });

    var noOrder = [];
    ref1.forEach((snapshot2) => {
      if (snapshot2.exists()) {
        if (!snapshot2.hasChild('order')) {
          //do stuff

          var hasContentID = snapshot2.hasChild('content');

          noOrder.push({
            key: snapshot2.key,
            val: snapshot2.val(),
            hasContentID: hasContentID,
          });
          /* tempCards.push(doc) */
        }
      }
    });

    const tempCards = noOrder.reverse().concat(hasOrder);
    setCollectionContent(tempCards);
    setLoading(false);
  }

  return (
    <div key="d" className={' relative flex w-full flex-col justify-between'}>
      <button
        onClick={() =>
          edit
            ? null
            : router.push(
                `/${colllectionCreatorSlug}/collections/${collectionSlug}`,
              )
        }
        className={`  ${
          edit ? 'cursor-move' : 'cursor-pointer'
        } group flex flex-row items-center justify-start space-x-2 px-6 pt-6 pb-2`}
      >
        <p
          className={` ${
            !edit ? 'duration-200 hover:text-gray-600' : null
          } font-primary text-xl font-bold`}
        >
          {collectionMeta.title}
        </p>
      </button>

      <div className={'h-[444px] overflow-y-scroll'}>
        {collectionContent.length === 0 ? (
          <div className={'w-full p-8'}>
            <EmptyStateSection
              icon={<CollectionIcon className={'h-8 w-8'} />}
              title={`This collection is empty`}
              text={'Once content is added, it will show up here'}
              noCTA={true}
            />
          </div>
        ) : (
          <div
            className={
              'flex w-full flex-shrink-0 flex-row space-x-4 overflow-x-scroll px-6 pb-6 pt-4'
            }
          >
            {collectionContent.map((item, i) => (
              <div className={' max-h-96 min-h-full w-72 flex-shrink-0'}>
                {' '}
                {item.val.type === 'thread' ? (
                  <ThreadSocialBlogCard
                    border={true}
                    socialBlog={true}
                    threadID={item.hasContentID ? item.val.content.id : null}
                    edit={false}
                    userMatch={
                      item.val.creator === colllectionCreatorID ? true : false
                    }
                    creatorID={colllectionCreatorID}
                    creatorSlug={colllectionCreatorSlug}
                    creatorMeta={creatorMeta}
                  />
                ) : item.val.type === 'essay' ? (
                  <EssaySocialBlogCard
                    border={true}
                    collection={true}
                    userID={colllectionCreatorID}
                    slug={colllectionCreatorSlug}
                    typeshareID={item.hasContentID ? item.val.content.id : null}
                    openEssaySelector={() => openEssaySelection(item.key)}
                    edit={false}
                  />
                ) : null}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
