import { ArrowUpIcon, XIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import EssayCard from '../../Cards/essayCard';
import EditIcon from '../../Icons/edit';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import PinIcon from '../../Icons/pin';
import CollectionSocialBlogCard from './collection';
import { useRouter } from 'next/router';

export default function CollectionRouterCard({
  edit,
  collectionID = null,
  removeCard,
  openCollectionSelector,
  userID,
  slug,
  collection = null,
}) {
  const [activeCollection, setActiveCollection] = useState();
  const [loading, setLoading] = useState(true);

  const router = useRouter();

  useEffect(() => {
    userID && slug && collectionID ? getActiveCollection() : null;
  }, [collectionID]);

  function getActiveCollection() {
    const ref = firebase
      .database()
      .ref('gallery/' + slug)
      .child(userID)
      .child('collections')
      .child(collectionID)
      .child('meta');
    ref
      .once('value')
      .then(function (snapshot) {
        if (snapshot.exists()) {
          setActiveCollection(snapshot.val());
        }
      })
      .then(() => {
        setLoading(false);
      });
  }

  const click = (event) => {
    event.stopPropagation();
    openCollectionSelector();
  };
  const close = (event) => {
    event.stopPropagation();
    removeCard();
  };
  const open = (event) => {
    event.stopPropagation();
    if (activeCollection) {
      router.push({
        pathname: '/[slug]/collections/[collection]',
        query: { slug: slug, collection: activeCollection.slug },
      });
    }
  };

  return (
    <div
      className={`${
        edit ? 'cursor-move' : 'cursor-pointer'
      } group relative h-full w-full`}
    >
      <div
        className={`${
          edit ? 'flex' : 'hidden'
        } absolute top-2 right-2 z-10 flex flex-row items-center justify-center space-x-2`}
      >
        <button
          onMouseDown={(event) => open(event)}
          onTouchStart={(event) => open(event)}
          className={` ${
            collectionID !== null && activeCollection ? 'flex' : 'hidden'
          } rounded-full bg-white p-2 opacity-0 ring-gray-200 transition-all duration-200 hover:ring-4 group-hover:opacity-100`}
        >
          <ArrowUpIcon
            className={'h-4 w-4 rotate-45 transform text-gray-600'}
          />
        </button>
        <button
          onMouseDown={(event) => close(event)}
          onTouchStart={(event) => close(event)}
          className={` rounded-full bg-white p-2 text-red-500 opacity-0  ring-red-100 transition-all duration-200 hover:ring-4 group-hover:opacity-100`}
        >
          <XIcon className={'h-4 w-4'} />
        </button>
      </div>

      {collectionID !== null && !loading && activeCollection !== undefined ? (
        <div
          className={`h-full w-full ${
            edit
              ? null
              : 'transform duration-200 hover:scale-105 active:scale-100'
          }`}
        >
          <CollectionSocialBlogCard
            tag={'Collection'}
            noScale={true}
            openEdit={() => null}
            creatorID={userID}
            edit={false}
            name={activeCollection.title}
            slug={activeCollection.slug}
            imageName={
              activeCollection.image !== undefined
                ? activeCollection.image.image
                : null
            }
            click={() =>
              edit
                ? null
                : router.push(`/${slug}/collections/${activeCollection.slug}`)
            }
          />
        </div>
      ) : !edit ? null : (
        <div
          style={{ boxShadow: '0px 4px 24px 0px #0000000D' }}
          className={` ${
            edit
              ? 'cursor-move'
              : 'transform cursor-pointer duration-200 hover:scale-105 active:scale-100'
          } group relative flex h-full w-full items-center justify-center overflow-hidden  rounded-3xl border border-dashed border-gray-300 bg-gray-100`}
        >
          <button
            onMouseDown={(event) => click(event)}
            onTouchStart={(event) => click(event)}
            className={`${
              !collectionID ? 'flex' : 'hidden'
            } transform rounded-full bg-gray-800 py-2 px-3 text-sm font-medium text-white backdrop-blur-xl  backdrop-filter duration-200 hover:scale-105 active:scale-100`}
          >
            <p>Select Collection</p>
          </button>
        </div>
      )}
    </div>
  );
}
