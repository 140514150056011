import { ArrowUpIcon, XIcon } from '@heroicons/react/solid';
import router, { useRouter } from 'next/router';
import EditIcon from '../../Icons/edit';

export default function TwitterSocialBlogCard({
  handle,
  editable = false,
  onEdit = null,
  edit,
  removeCard,
}) {
  const router = useRouter();

  const open = (event) => {
    event.stopPropagation();
    router.push(`https://twitter.com/${handle}`);
  };
  const close = (event) => {
    event.stopPropagation();
    removeCard();
  };

  return (
    <a
      href={edit ? null : `https://twitter.com/${handle}`}
      target={'_blank'}
      className={`rounded-2xl bg-[#1DA1F2] p-12 ${
        edit
          ? 'cursor-move'
          : 'transform cursor-pointer duration-200 hover:scale-105 active:scale-100'
      } group relative flex h-full w-full items-center justify-center`}
    >
      <div
        className={`${
          edit ? 'flex' : 'hidden'
        } absolute top-2 right-2 flex flex-row items-center justify-center space-x-2`}
      >
        <button
          onMouseDown={(event) => open(event)}
          onTouchStart={(event) => open(event)}
          className={
            'cursor-pointer rounded-full bg-white p-2 opacity-0 ring-gray-200 transition-all duration-200 hover:ring-4 group-hover:opacity-100'
          }
        >
          <ArrowUpIcon
            className={'h-4 w-4 rotate-45 transform text-gray-600'}
          />
        </button>
        <button
          onMouseDown={(event) => close(event)}
          onTouchStart={(event) => close(event)}
          className={` rounded-full bg-white p-2 text-red-500 opacity-0  ring-red-100 transition-all duration-200 hover:ring-4 group-hover:opacity-100`}
        >
          <XIcon className={'h-4 w-4'} />
        </button>
      </div>

      <svg
        className={'w-1/4 fill-current text-white'}
        viewBox="0 0 21 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M21 2.77246C20.2271 3.10039 19.3978 3.32279 18.5264 3.42208C19.4161 2.91193 20.0972 2.10275 20.42 1.14152C19.5854 1.61395 18.6642 1.957 17.6826 2.14297C16.8966 1.34007 15.7785 0.839966 14.5384 0.839966C12.1593 0.839966 10.2303 2.68703 10.2303 4.96382C10.2303 5.28673 10.2683 5.60211 10.3418 5.9037C6.76201 5.73153 3.58768 4.08929 1.46314 1.59385C1.09176 2.20201 0.880492 2.91065 0.880492 3.66709C0.880492 5.09827 1.64161 6.36105 2.7964 7.09985C2.0904 7.07725 1.42639 6.89128 0.84508 6.58217V6.63369C0.84508 8.63153 2.33056 10.2989 4.30024 10.6784C3.93936 10.7714 3.55882 10.8229 3.16514 10.8229C2.88696 10.8229 2.61794 10.7965 2.35417 10.7462C2.90267 12.386 4.49315 13.5784 6.37756 13.6111C4.90388 14.7168 3.04573 15.374 1.02749 15.374C0.679756 15.374 0.337236 15.3539 0 15.3174C1.90671 16.4898 4.17036 17.1733 6.60327 17.1733C14.528 17.1733 18.8597 10.8882 18.8597 5.43754L18.8453 4.90353C19.6917 4.32549 20.4239 3.59924 21 2.77246Z" />
      </svg>
    </a>
  );
}
