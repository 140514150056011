/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';
import router from 'next/router';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/analytics';
import ConnectTwitter from '../Modal/connect-twitter';
import ReconnectTwitter from '../Modal/reconnect-twitter';
import EssayCard from '../Cards/essayCard';

import { RadioGroup } from '@headlessui/react';

const backgrounds = [
  { id: 0, bg: 'bg-white border border-gray-200', text: 'text-gray-900' },
  {
    id: 1,
    bg: 'bg-gradient-to-b from-gray-200 to-gray-300',
    text: 'text-gray-900',
  },
  {
    id: 2,
    bg: 'bg-gradient-to-b from-gray-900 to-gray-800',
    text: 'text-white',
  },
  {
    id: 3,
    bg: 'bg-gradient-to-b from-blue-500 to-indigo-600',
    text: 'text-white',
  },
  {
    id: 4,
    bg: 'bg-gradient-to-b from-red-400 to-orange-400',
    text: 'text-white',
  },
  {
    id: 5,
    bg: 'bg-gradient-to-b from-yellow-400 to-yellow-500',
    text: 'text-white',
  },
  {
    id: 6,
    bg: 'bg-gradient-to-b from-pink-500 to-indigo-500',
    text: 'text-white',
  },
  {
    id: 7,
    bg: 'bg-gradient-to-b from-green-500 to-green-600',
    text: 'text-white',
  },
];

export default function TextCardEditor({
  open,
  setOpen,
  card,
  refreshCards,
  loggedInUserID,
  slug,
}) {
  const cancelButtonRef = useRef();

  const [title, setTitle] = useState('');
  const [background, setBackground] = useState(0);
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    if (card !== null) {
      setTitle(card.val().content.title);
      setBackground(card.val().content.background);
      setDescription(card.val().content.description);
      setLink(card.val().content.link);
      setActiveKey(card.key);
    }
  }, [card]);

  const saveTextCard = () => {
    firebase
      .database()
      .ref('gallery/' + slug + '/' + loggedInUserID + '/blog/cards/')
      .child(activeKey)
      .child('content')
      .update({
        background,
        title,
        description,
        link,
      })
      .then(refreshCards);
  };

  return (
    <>
      <Transition.Root initialFocus={cancelButtonRef} show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-40 overflow-y-auto"
          open={open}
          onClose={setOpen}
        >
          <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center antialiased sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-md backdrop-filter transition-all" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-24 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-24 sm:scale-95"
            >
              <div
                className={`relative inline-block transform overflow-hidden rounded-2xl bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:align-middle`}
              >
                <button
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                  className={
                    'absolute top-2 right-2 transform rounded-full border border-gray-200 p-1.5 text-gray-700 outline-none duration-200 hover:-translate-y-0.5 hover:text-gray-900 hover:outline-none focus:outline-none'
                  }
                >
                  <svg className={'h-5 w-5'} fill="none" viewBox="0 0 24 24">
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M17.25 6.75L6.75 17.25"
                    ></path>
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M6.75 6.75L17.25 17.25"
                    ></path>
                  </svg>
                </button>

                <div
                  className={'flex flex-col items-start justify-center pt-8'}
                >
                  <Dialog.Title
                    as="h3"
                    className="mb-8 px-8 text-left font-primary text-3xl font-bold text-gray-900 "
                  >
                    Edit Text Card
                  </Dialog.Title>

                  <div
                    className={
                      'flex w-full flex-col items-center justify-center space-y-6 px-8 pb-8'
                    }
                  >
                    <div className={'flex w-full flex-col space-y-1'}>
                      <p
                        className={
                          'font-primary text-lg font-semibold text-gray-900'
                        }
                      >
                        Background
                      </p>
                      <div className="mt-1 flex w-full flex-row space-x-2">
                        <RadioGroup
                          value={background}
                          onChange={setBackground}
                          className={'flex w-full flex-row space-x-2'}
                        >
                          {backgrounds.map((bg, i) => (
                            <RadioGroup.Option
                              className={
                                'h-8 w-8 rounded-full bg-gray-300 focus:outline-none'
                              }
                              value={bg.id}
                            >
                              {({ checked }) => (
                                <button
                                  className={`${
                                    bg.bg
                                  } h-8 w-8 rounded-full focus:outline-none focus:ring-0 ${
                                    checked
                                      ? 'ring-2 ring-gray-900 ring-offset-2'
                                      : 'ring-0'
                                  }`}
                                />
                              )}
                            </RadioGroup.Option>
                          ))}
                        </RadioGroup>
                      </div>
                    </div>

                    <div className={'flex w-full flex-col space-y-1'}>
                      <p
                        className={
                          'font-primary text-lg font-semibold text-gray-900'
                        }
                      >
                        Title
                      </p>
                      <div className="mt-1 w-full">
                        <input
                          value={title}
                          placeholder={'My Card Title'}
                          onChange={(event) => setTitle(event.target.value)}
                          className={
                            'w-full rounded-lg border-2 border-gray-200 bg-white p-3 font-medium transition duration-200 focus:border-gray-900 focus:outline-none focus:ring-0'
                          }
                        />
                      </div>
                    </div>

                    <div className={'flex w-full flex-col space-y-1'}>
                      <p
                        className={
                          'font-primary text-lg font-semibold text-gray-900'
                        }
                      >
                        Description
                      </p>
                      <div className="mt-1 w-full">
                        <textarea
                          placeholder={
                            'This is a card description and goes under the title.'
                          }
                          value={description}
                          onChange={(event) =>
                            setDescription(event.target.value)
                          }
                          id="bio"
                          name="bio"
                          rows={4}
                          maxLength={160}
                          className={
                            'w-full rounded-lg border-2 border-gray-200 bg-white p-3 font-medium transition duration-200 focus:border-gray-900 focus:outline-none focus:ring-0'
                          }
                        />
                      </div>
                    </div>

                    <div className={'flex w-full flex-col space-y-1'}>
                      <div className={'flex flex-col'}>
                        <p
                          className={
                            'font-primary text-lg font-semibold text-gray-900'
                          }
                        >
                          Link
                        </p>
                        <p
                          className={
                            'font-sans text-sm font-medium text-gray-500'
                          }
                        >
                          Where should we send people who click on this card?
                          (optional)
                        </p>
                      </div>
                      <div className="mt-1 w-full">
                        <input
                          placeholder={'https://typeshare.co'}
                          value={link}
                          onChange={(event) => setLink(event.target.value)}
                          className={
                            'w-full rounded-lg border-2 border-gray-200 bg-white p-3 font-medium transition duration-200 focus:border-gray-900 focus:outline-none focus:ring-0'
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className={'w-full bg-gray-100 p-4 px-8'}>
                    <button
                      onClick={saveTextCard}
                      type="button"
                      className={
                        'w-full transform rounded-lg bg-gray-900 p-3 font-primary font-semibold text-white duration-200 hover:scale-105 active:scale-100'
                      }
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
