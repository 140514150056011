import { ArrowUpIcon, XIcon } from '@heroicons/react/solid';
import EditIcon from '../../Icons/edit';

export default function QuoteSocialBlogCard({ title, removeCard, edit }) {
  return (
    <div className={'group relative h-full w-full cursor-move p-6'}>
      <div className={'flex flex-col items-start justify-center'}>
        <p className={'-mb-4 font-primary text-6xl text-gray-700'}>“</p>

        <p className={'font-serif'}>{title}</p>
      </div>

      {edit ? (
        <div
          className={`${
            edit ? 'flex' : 'hidden'
          } absolute top-2 right-2 flex-row items-center justify-center space-x-2`}
        >
          <button
            className={`rounded-full border border-gray-200 bg-white p-1.5 opacity-0  ring-gray-200 transition-all duration-200 hover:text-gray-700 hover:ring-4 group-hover:opacity-100`}
          >
            <EditIcon className={'h-6 w-6'} />
          </button>

          <button
            onClick={() => removeCard()}
            className={`rounded-full border border-gray-200 bg-white p-2.5 opacity-0  ring-red-100 transition-all duration-200 hover:text-red-500 hover:ring-4 group-hover:opacity-100`}
          >
            <XIcon className={'h-5 w-5'} />
          </button>
        </div>
      ) : null}
    </div>
  );
}
