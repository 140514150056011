import { ArrowUpIcon } from '@heroicons/react/solid';
import EditIcon from '../../Icons/edit';
import { Fragment, useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';

export default function CollectionSocialBlogCard({
  bgImage,
  name,
  noScale = false,
  size,
  edit = false,
  tag = null,
  click,
  imageName,
  slug,
  creatorID,
  openEdit,
  type = 'large',
}) {
  const [imageURL, setImageURL] = useState('');

  useEffect(() => {
    if (slug && imageName) {
      getImage(slug, imageName);
    }
  }, [imageName]);

  const getImage = (colSlug, imageKey) => {
    var storageRef2 = firebase.storage().ref();
    var imgRef = storageRef2
      .child(creatorID)
      .child('collections')
      .child(colSlug)
      .child(`${imageKey}`);

    imgRef.getDownloadURL().then((url) => {
      setImageURL(url);
    });
  };

  const openEditModal = (event) => {
    event.stopPropagation();
    openEdit();
  };

  return (
    <div
      onClick={click}
      style={{ boxShadow: 'rgba(0, 0, 0, 0.05) 0px 10px 40px 0px' }}
      className={`${
        noScale
          ? null
          : 'transform duration-200 hover:scale-105 active:scale-100'
      } group relative h-full w-full overflow-hidden  rounded-2xl`}
    >
      <img src={imageURL} className={'absolute h-full w-full object-cover'} />

      <div
        className={`absolute h-full w-full bg-gradient-to-b from-transparent to-[#00000050]  ${
          type === 'small' ? 'p-3' : 'p-6'
        } flex items-end justify-start`}
      >
        <div
          className={`${
            tag ? 'flex' : 'hidden'
          } absolute top-2 left-2 rounded-full bg-black bg-opacity-25 py-2 px-3 text-sm font-medium text-white backdrop-blur-xl backdrop-filter`}
        >
          <p>{tag}</p>
        </div>

        <p
          className={`font-primary text-white ${
            type === 'small' ? 'text-lg' : 'text-xl'
          } font-semibold`}
        >
          {name}
        </p>
      </div>

      <div
        className={`${
          edit ? 'flex' : 'hidden'
        } absolute top-2 right-2 flex-row items-center justify-center space-x-2`}
      >
        <button
          onClick={(event) => openEditModal(event)}
          className={`rounded-full bg-black bg-opacity-25 p-1.5 text-white opacity-0 ring-white backdrop-blur-xl backdrop-filter transition-all duration-200 hover:ring-4 group-hover:opacity-100`}
        >
          <EditIcon className={'h-6 w-6'} />
        </button>
      </div>
    </div>
  );
}
