import { ArrowUpIcon, XIcon } from '@heroicons/react/solid';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import EssayCard from '../../Cards/essayCard';
import ThreadCard from '../../Cards/thread';
import EmptyStateSection from '../../EmptyState';
import EditIcon from '../../Icons/edit';
import EssayIconDetailed from '../../Icons/essay-icon-detailed';
import ThreadIconDetailed from '../../Icons/thread-icon-detailed';
import ThreadSocialBlogCard from './thread';
var twitter = require('twitter-text');

export default function ContentCard({
  blogMeta,
  typeshares,
  threads,
  edit,
  colllectionCreatorID,
  colllectionCreatorSlug,
  removeCard,
}) {
  const [filter, setFilter] = useState('imageEssay');
  const [posts, setPosts] = useState([]);

  const router = useRouter();

  const autoLinkTweet = (tweet) => {
    var tweetElement = twitter.default.autoLink(tweet);

    var regexpAt = /@/g;
    var matchAt = regexpAt.exec(tweet);

    while (matchAt != null) {
      tweetElement = tweetElement.replaceAll(
        matchAt[0],
        `<span class='text-blue-400'>${matchAt[0]}</span>`,
      );
      matchAt = regexpAt.exec(tweet);
    }

    return tweetElement;
  };

  const getDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const newDate = new Date(date).toLocaleDateString(undefined, options);
    return newDate;
  };

  const close = (event) => {
    event.stopPropagation();
    removeCard();
  };

  const changeFilter = (event, val) => {
    event.stopPropagation();
    setFilter(val);
  };

  useEffect(() => {
    combineTypesharesAndThreadsSortByDate(typeshares, threads);
  }, [threads, typeshares]);

  const combineTypesharesAndThreadsSortByDate = (typeshares, threads) => {
    //if there are any duplicates (same key) - remove the duplicate thread
    var combined = typeshares.concat(threads);
    combined.sort(function (a, b) {
      //if it doesn't have a timestamp sort it to the bottom
      //put newest at the top
      if (!a.hasChild('timestamp/unix')) {
        return 1;
      } else if (!b.hasChild('timestamp/unix')) {
        return -1;
      } else {
        return b.val().timestamp.unix - a.val().timestamp.unix;
      }
    });

    setPosts(combined);
  };

  const formatTS = (ts) => {
    if (ts) {
      var format = {
        id: ts.key,
        val: ts.val(),
      };
      return format;
    } else {
      return null;
    }
  };

  return (
    <div
      key="d"
      className={' group relative flex w-full flex-col justify-between'}
    >
      <div
        className={`${
          edit ? 'flex' : 'hidden'
        } absolute top-2 right-2 z-10 flex flex-row items-center justify-center space-x-2`}
      >
        <button
          onMouseDown={(event) => close(event)}
          onTouchStart={(event) => close(event)}
          className={` rounded-full bg-white p-2 text-red-500 opacity-0  ring-red-100 transition-all duration-200 hover:ring-4 group-hover:opacity-100`}
        >
          <XIcon className={'h-4 w-4'} />
        </button>
      </div>

      <div
        className={
          'flex w-full flex-row items-center justify-between px-6 pt-6 pb-2'
        }
      >
        <div
          className={
            ' mr-auto flex w-max flex-row items-center justify-center space-x-6 font-sans'
          }
        >
          <p className="font-primary text-xl font-semibold text-gray-900">
            All Posts
          </p>
          {/*  <button onMouseDown={(event) => changeFilter(event, "imageEssay")} onTouchStart={(event) => changeFilter(event, "imageEssay")} className={` ${filter === "imageEssay" ? "  text-gray-900 border-gray-900" : "text-gray-600 border-transparent"} pb-2 text-base border-b-2 antialiased font-primary font-semibold`}>Essays</button> */}
          {/*   <button onMouseDown={(event) => changeFilter(event, "threads")} onTouchStart={(event) => changeFilter(event, "threads")} className={` ${filter === "threads" ? "  text-gray-900 border-gray-900" : "text-gray-600 border-transparent"} pb-2 text-base antialiased border-b-2 font-primary font-semibold`}>Threads</button> */}
        </div>
      </div>

      <div>
        {filter === 'imageEssay' ? (
          posts.length === 0 ? (
            <div className={'w-full p-8'}>
              <EmptyStateSection
                icon={<EssayIconDetailed className={'h-12 w-12'} />}
                title={'No Published Essays'}
                text={`${blogMeta.displayName} hasn't published any essays yet.`}
                noCTA={true}
              />
            </div>
          ) : (
            <div
              className={
                'flex w-full flex-shrink-0 flex-row space-x-4 overflow-x-scroll px-6 pb-6 pt-4'
              }
            >
              {posts.map((typeshare, i) => (
                <div className={'max-h-96 min-h-full w-72 flex-shrink-0'}>
                  <EssayCard
                    border={true}
                    creatorID={colllectionCreatorID}
                    creatorSlug={colllectionCreatorSlug}
                    socialBlog={true}
                    typeshare={formatTS(typeshare)}
                    click={() =>
                      router.push({
                        pathname: '/[slug]/posts/[post]',
                        query: { slug: blogMeta.slug, post: typeshare.key },
                      })
                    }
                  />
                </div>
              ))}
            </div>
          )
        ) : threads.length === 0 ? (
          <div className={'w-full p-8'}>
            <EmptyStateSection
              icon={<ThreadIconDetailed className={'h-12 w-12'} />}
              title={'No Published Threads'}
              text={`${blogMeta.displayName} hasn't published any threads yet.`}
              noCTA={true}
            />
          </div>
        ) : (
          <div
            className={
              'flex w-full flex-shrink-0 flex-row space-x-4 overflow-x-scroll p-6 pt-4'
            }
          >
            {threads.map((item, i) => (
              <div
                className={`min-w-72 max-w-72 max-h-96 min-h-full w-72 flex-shrink-0 transform duration-200 hover:scale-105 active:scale-100`}
              >
                <ThreadCard
                  border={true}
                  borderType={2}
                  click={() =>
                    router.push({
                      pathname: '/[slug]/threads/[thread]',
                      query: { slug: colllectionCreatorSlug, thread: item.key },
                    })
                  }
                  threadID={item.key}
                  userMatch={true}
                  edit={false}
                  creatorID={colllectionCreatorID}
                  creatorSlug={colllectionCreatorSlug}
                  creatorMeta={blogMeta}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
